define(['app', '$console', 'siteObj', 'accessibilityFocusHelper'], (app, $console, siteObj, accessibilityFocusHelper) => () => {
  const ZOOM_LEVEL = 3;

  const _channels = {
    updateImageChannel: 'productMultipleImages/newImage',
    updateImageOverlayChannel: 'productCarouselImageOverlay/update'
  };

  const _selectors = {
    leftArrow: '.productImageCarousel_leftArrow',
    rightArrow: '.productImageCarousel_rightArrow',
    thumbnail: '.productImageCarousel_thumbnail',
    thumbnailWrapper: '.productImageCarousel_thumbnailWrapper',
    imageThumbnailWrapper: '[data-thumbnail-type="image"]',
    videoThumbnailWrapper: '[data-thumbnail-type="video"]',
    imageWrapper: '.productImageCarousel_imageWrapper',
    imageSlider: '.productImageCarousel_imageSlider',
    image: '.productImageCarousel_image',
    imagePreview: '.productImageCarousel_imagePreview',
    video: '.videoPlayer',
    videoContainer: '.videoPlayer_container',
    zoom: '.productImageCarousel_zoom',
    path: '[data-path]',
    component: '[data-component="productImageCarousel"]',
    openTranscriptButton: '.videoPlayer_transcriptButton',
  };

  const _attr = {
    active: 'data-active',
    index: 'data-index',
    path: 'data-path',
    size: 'data-size',
    thumbnailType: 'data-thumbnail-type',
    dataAlt: 'data-alt',
    hide: 'data-hide'
  };

  const _touch = {
    history: [],
    historyMaxLength: 5,
    threshold: 1,
  };

  const comp = {
    element: null,
    slider: null,
    previousButton: null,
    nextButton: null,
    zoomButton: null,
    selection: 0,
    thumbnails: [],
    thumbnailWrappers: [],
    wrappers: [],
    images: [],
    _touch,
    _attr,
  };

  comp.init = function (element, manualCall) {
    comp.element = element;
    comp.slider = element.querySelector(_selectors.imageSlider);
    comp.previousButton = element.querySelector(_selectors.leftArrow);
    comp.nextButton = element.querySelector(_selectors.rightArrow);
    comp.zoomButton = element.querySelector(_selectors.zoom);
    comp.thumbnails = [...element.querySelectorAll(_selectors.thumbnail)];
    comp.thumbnailWrappers = [...element.querySelectorAll(_selectors.thumbnailWrapper)];
    comp.imageThumbnailWrappers = [...element.querySelectorAll(_selectors.imageThumbnailWrapper)];
    comp.videoThumbnailWrapper = element.querySelector(_selectors.videoThumbnailWrapper);
    comp.video = element.querySelector(_selectors.video);
    comp.videoContainer = element.querySelector(_selectors.videoContainer);
    comp.wrappers = [...element.querySelectorAll(_selectors.imageWrapper)];

    // Transcript elements
    comp.openTranscriptButton = element.querySelector(_selectors.openTranscriptButton);

    comp.images = comp.wrappers.map((wrapper, index) => {
      const type = wrapper.getAttribute('data-type') === 'video' ? 'video' : 'image';
      const element = type === 'image' ?
        wrapper.querySelector(_selectors.image) : wrapper.querySelector(_selectors.video);
      const images = [...wrapper.querySelectorAll(_selectors.path)];
      const preview = wrapper.querySelector(_selectors.imagePreview);

      // filter unknown sizes and map to srcset
      const tmpImages =
        images
          .map(image => {
            const path = image.getAttribute(_attr.path);
            const size = parseInt(image.getAttribute(_attr.size));

            return {path, size};
          })
          .filter(image => !isNaN(image.size) && image.size > 0);

      const src = (() => {
        const sizes = tmpImages.map(({size}) => size);
        if (sizes.length > 0) {
          const largestSize = Math.max(...sizes);
          const largestImage = tmpImages.find(({size}) => largestSize === size);
          return largestImage ? largestImage.path : '';
        } else {
          return '';
        }
      })();
      const srcset = tmpImages.sort((i0, i1) => i0.size - i1.size).map(image => `${image.path} ${image.size}w`).join(', ');
      const zoomedSrcset = tmpImages.map(image => `${image.path} ${parseInt(image.size / ZOOM_LEVEL)}w`).join(', ');

      const thumbnailSrc = type === 'image' ? comp.thumbnails[index].src : '';

      return {
        loaded: false,
        thumbnail: thumbnailSrc,
        type: type,
        element,
        src,
        srcset,
        zoomedSrcset,
        preview: preview
      };
    });

    comp.toggleArrows();
    comp.bind();
    comp.setAccessibleThumbnailNames();
    comp.navigate(0, true);

    if (!manualCall) {
      app.subscribe(_channels.updateImageChannel, comp.updateImage);
    }
  };

  comp.toggleArrows = function () {
    if (comp.images.length < 2) {
      return;
    }

    comp.previousButton.classList.add('show');
    comp.nextButton.classList.add('show');
  };

  comp.setAccessibleThumbnailNames = () => {
    comp.imageThumbnailWrappers.forEach((thumbnail, index) => {
      const accessibleName = thumbnail.getAttribute('aria-label').replace('-', `${index + 1} -`);
      thumbnail.setAttribute('aria-label', accessibleName);
      thumbnail.setAttribute('title', accessibleName);
    });

    if(comp.videoThumbnailWrapper && comp.video){
      const videoARIALabel = comp.video.getAttribute('aria-label');
      videoARIALabel && comp.videoThumbnailWrapper.setAttribute('aria-label', videoARIALabel);
    }
  };

  comp.bind = function () {
    comp.previousButton.addEventListener('click', comp.previous);
    comp.nextButton.addEventListener('click', comp.next);
    comp.zoomButton.addEventListener('click', comp.zoomClick);
    comp.slider.addEventListener('touchstart', comp.touchstart, {passive: true});
    comp.slider.addEventListener('touchmove', comp.touchmove, {passive: true});
    comp.slider.addEventListener('touchend', comp.touchend, {passive: true});

    comp.thumbnailWrappers.map((thumbnail, index) => {
      thumbnail._index = index;
      thumbnail.addEventListener('click', comp.thumbnailClick);
    });

    comp.images.map((image, index) => {
      image.element._index = index;
    });
  };

  comp.updateImage = function ({productId, variation, personalised}) {
    productId = productId || siteObj.productId;

    app.ajax.get({
      url: `/${productId}.images?variation=${variation}&isPersonalisableProduct=${personalised}`,
      success: (result) => comp.updateImageSuccess(result, personalised),
      error: () => comp.updateImageError(productId, variation),
    });
  };

  comp.updateImageSuccess = function (result, personalised) {
    const parent = comp.element.parentNode;

    if (!parent) {
      $console.error('There is no parent for the component,');
      return;
    }

    comp.element.outerHTML = result;
    comp.element = parent.querySelector(_selectors.component);

    if (personalised) {
      app.publish(_channels.updateImageOverlayChannel);
    }

    comp.init(comp.element, true);
  };

  comp.updateImageError = function (productId, variation) {
    $console.error(`Could not load product images. [productId=${productId}, variation=${variation}]`);
  };

  comp.navigate = function (index, firstLoad = false) {
    let announcementMessage;
    if (!comp.images[index]) {
      return;
    }

    if (comp.selection !== -1) {
      if (comp.thumbnailWrappers[comp.selection]) {
        comp.thumbnailWrappers[comp.selection].setAttribute(_attr.active, 'false');
      }
    }

    comp.selection = index;
    if (comp.thumbnailWrappers[index]) {
      comp.thumbnailWrappers[index].setAttribute(_attr.active, 'true');
    }

    if (document.getElementsByTagName('html')[0].getAttribute('dir') === 'ltr') {
      comp.slider.style['margin-left'] = `${index * -100}%`;
    } else {
      comp.slider.style['margin-right'] = `${index * -100}%`;
    }


    const {element, src, srcset, loaded} = comp.images[index];

    const type = element.tagName.toLowerCase();

    if (!loaded && type === 'img') {
      element.removeAttribute('width');
      element.removeAttribute('height');
      element.addEventListener('load', comp.imageLoaded);
      element.src = src;
      element.srcset = srcset;
    }

    let altText = comp.images[index].element.getAttribute(_attr.dataAlt);
    altText = altText ? '- ' + altText : '';

    const videoElement = comp.video;
    if (videoElement && videoElement !== 'undefined') {
      if (type === 'video') {
        comp.videoContainer.removeAttribute('aria-hidden');
        accessibilityFocusHelper.enableElement(videoElement);
        accessibilityFocusHelper.enableElement(comp.openTranscriptButton);

        videoElement.play();
        comp.zoomButton.style['visibility'] = 'hidden';

        comp.slider.addEventListener('transitionend', function setFocusOnVideoAfterScroll() {
          comp.videoContainer.focus();
          comp.slider.removeEventListener('transitionend', setFocusOnVideoAfterScroll);
        });

      } else {

        comp.videoContainer.setAttribute('aria-hidden', 'true');
        accessibilityFocusHelper.disableElement(videoElement);
        accessibilityFocusHelper.disableElement(comp.openTranscriptButton);

        videoElement.pause();


        comp.zoomButton.style['visibility'] = 'visible';

        const imageIndex = index === 0 ? 1 : index;

        announcementMessage = `Showing image ${imageIndex} ${altText}`;
      }
    } else {
      announcementMessage = `Showing image ${index + 1} ${altText}`;
    }

    !firstLoad
    && announcementMessage
    && app.publish('accessibility/announce', 'assertive', announcementMessage);
  };

  comp.previous = function () {
    const index = comp.selection - 1;
    comp.navigate(index >= 0 ? index : comp.images.length - 1);
    app.publish('tracking/record', 'productImageCarousel', 'click', 'previous image');
  };

  comp.next = function () {
    const index = comp.selection + 1;
    comp.navigate(index < comp.images.length ? index : 0);
    app.publish('tracking/record', 'productImageCarousel', 'click', 'next image');
  };

  comp.zoomClick = function () {
    const images = comp.images.map(({thumbnail, zoomedSrcset, src, type, element}) =>
      ({thumbnail, srcset: zoomedSrcset, src, type, alt: element.getAttribute(_attr.dataAlt)}))
      .filter(({type}) => type !== 'video');

    app.publish('productImageZoom/open', images, comp.selection);
    app.publish('tracking/record', 'productImageCarousel', 'open', 'zoom');
  };

  comp.thumbnailClick = function (ev) {
    comp.navigate(ev.currentTarget._index);
    app.publish(
      'tracking/record',
      'productImageCarousel',
      'click',
      'thumbnail',
      ev.currentTarget._index);
  };

  comp.imageLoaded = function (ev) {
    const index = ev.currentTarget._index;

    if (comp.images[index].loaded) {
      return;
    }

    comp.images[index].loaded = true;

    comp.images[index].preview.setAttribute(_attr.hide, 'true');
    comp.images[index].element.setAttribute(_attr.hide, 'false');
  };

  comp.touchstart = function (ev) {
    _touch.history = [{
      x: ev.changedTouches[0].pageX,
      t: ev.timeStamp,
    }];
  };

  comp.touchmove = function (ev) {
    _touch.history.push({
      x: ev.changedTouches[0].pageX,
      t: ev.timeStamp,
    });

    if (_touch.history.length > _touch.historyMaxLength) {
      _touch.history.shift();
    }
  };

  comp.touchend = function () {
    // Last touch is unreliable, don't push to history
    var velX = 0;

    for (var index = 1; index < _touch.history.length; index++) {
      var deltaX = _touch.history[index].x - _touch.history[index - 1].x;
      var deltaT = _touch.history[index].t - _touch.history[index - 1].t;

      velX += deltaX / deltaT;
    }

    if (Math.abs(velX) < _touch.threshold) {
      return;
    }

    if (velX > 0) {
      comp.previous();
    } else {
      comp.next();
    }

    app.publish('columbo/track', 'productImageCarousel', 'swipe', 'carousel image');
  };

  return comp;
});
